@import "../../mixins";

.tab-list {
  width: 100%;

  &__list {
    position: relative;

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    overflow: hidden;

    @include scrollbarDelete;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 1px;

      background-color: var(--stroke-dark-secondary);

      @include mediaBigDesktop {
        height: big(1);
      }
    }

    & .tab {
      &__content {
        border-bottom: none;

        &::before {
          bottom: 0;
        }
      }

      &:first-child {
        & .tab {
          &__content {
            // padding-left: 0;
          }
        }
      }
    }

    &.scroll {
      right: var(--container-thirty-offset);

      padding: 0 var(--container-thirty-offset);
      width: calc(100% + (var(--container-thirty-offset) * 2));
      align-items: center;

      overflow: auto;

      &::before {
        content: unset;
      }

      & .tab {
        border-bottom: solid 1px var(--stroke-dark-secondary);
      }
    }
  }
}
