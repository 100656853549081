@mixin mediaBigDesktop {
  @media screen and (min-width: #{rem(2561)}) {
    @content;
  }
}

@mixin mediaDesktop {
  @media screen and (min-width: #{rem(2201)}) {
    @content;
  }
}

@mixin mediaLaptop {
  @media screen and (max-width: #{rem(1700)}) {
    @content;
  }
}

@mixin mediaTablet {
  @media screen and (max-width: #{rem(1360)}) {
    @content;
  }
}

@mixin mediaMobile {
  @media screen and (max-width: #{rem(630)}) {
    @content;
  }
}

@mixin media($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}
