@mixin scrollbar {
  &::-webkit-scrollbar {
    width: rem(13);

    @include mediaBigDesktop {
      width: big(13);
    }
  }

  &::-webkit-scrollbar-track {
    background: var(--bg-white);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--stroke-dark-secondary);
    border-radius: 0;
    border: 4px solid var(--bg-white);

    cursor: pointer;

    @include mediaBigDesktop {
      border-radius: big(999);
      border-width: big(4);
    }

    @include hover {
      background-color: var(--accent-light-primary);
    }
  }
}

@mixin scrollbarHorizontal {
  &::-webkit-scrollbar {
    height: rem(13);

    @include mediaBigDesktop {
      height: big(13);
    }
  }

  &::-webkit-scrollbar-track {
    background-color: var(--bg-white);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--stroke-dark-secondary);
    border-radius: 0;
    border: 4px solid var(--bg-white);

    @include mediaBigDesktop {
      border-radius: big(999);
      border-width: big(4);
    }

    @include hover {
      background-color: var(--accent-light-primary);
    }
  }
}

@mixin scrollbarDelete {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}
